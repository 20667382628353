import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';
import { Helmet } from 'react-helmet';

// External Components
import { Box } from '@thepuzzlers/pieces';

// Local Components
import { Header, Benefit } from 'sections/solution';
import { useKisSolutionSellPageData } from 'graphqlHooks';
import {
  BowlingBar,
  FourLeafsSeaweed,
  OutlineClaw,
  Seaweed,
  Spacer
} from 'components';
import { Cta } from 'components/section';

// Assets
import thumbnail from 'assets/seo/thumbnail.png';
import { useSolutionPageData } from 'sections/solution';
import {
  BenefitSectionSpacer,
  ProductCatalogCard,
  FlexseedBreadCard,
  GetPaidCard,
  HeaderSectionSpacer
} from 'sections/solution/benefit/components';

// Data

const SolutionSell = () => {
  const { seo } = useSolutionPageData();
  const {
    header: cmsHeader,
    benefits: { solution_benefits }
  } = useKisSolutionSellPageData();

  return (
    <>
      <SEO {...seo} />
      <Helmet>
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />
        <meta property="og:image" content={thumbnail} />

        {/* Twitter */}
        <meta property="twitter:url" content={seo.url} />
        <meta property="twitter:image" content={thumbnail} />
      </Helmet>
      <Header data={cmsHeader} ImageDecoration={ImageDecoration} />
      <HeaderSectionSpacer />
      <Box as="main">
        <Benefit data={solution_benefits[0].data} isFirstBenefit>
          <ProductCatalogCard />
        </Benefit>
        <DecoratedSpacerOne />
        <Benefit data={solution_benefits[1].data} isRightSideTextBlock>
          <FlexseedBreadCard />
        </Benefit>
        <DecoratedSpacerTwo />
        <Benefit data={solution_benefits[2].data}>
          <GetPaidCard />
        </Benefit>
        <Spacer
          height={['23.3rem', null, '28.5rem', null, '41.3rem', '33.9rem']}
        />
        <Cta />
      </Box>
      <Spacer height={['8rem', null, '13.5rem', null, '6.3rem', '9.7rem']} />
    </>
  );
};

export default injectIntl(SolutionSell);

// Elements

const ImageDecoration = () => {
  return (
    <>
      <FourLeafsSeaweed
        sx={{
          width: ['6.3rem', null, '7.9rem', null, '10.1rem', '11.7rem'],
          position: 'absolute',
          bottom: ['-2.2rem', null, '-3.3rem', null, '-5.8rem', '-6.7rem'],
          left: ['-3.1rem', null, '-3.9rem', null, 'unset', 'unset'],
          right: [null, null, null, null, '-5rem', '-5.8rem'],
          zIndex: -1
        }}
      />
      <OutlineClaw
        sx={{
          width: ['3.67rem', null, '4.7rem', null, '6.3rem', '6.9rem'],
          position: 'absolute',
          top: ['1.7rem', null, '2.4rem', null, '3.1rem', '3.5rem'],
          right: ['2.2rem', null, '3.5rem', null, '2.4rem', '3.9rem']
        }}
      />
    </>
  );
};

const DecoratedSpacerOne = () => {
  return (
    <BenefitSectionSpacer>
      <BowlingBar
        sx={{
          position: 'absolute',
          width: '3.5rem',
          transform: 'rotate(34deg)'
        }}
      />
    </BenefitSectionSpacer>
  );
};
const DecoratedSpacerTwo = () => {
  return (
    <BenefitSectionSpacer>
      <Seaweed
        sx={{
          position: 'absolute',
          width: '9.5rem'
        }}
      />
    </BenefitSectionSpacer>
  );
};
